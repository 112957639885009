import { Router } from "@reach/router"

import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import Article from "../components/shared/article/article"
import { AboutUsPage } from "../modules/AboutUsPage"
import { ContactPage } from "../modules/ContactPage"
import { HomePage } from "../modules/HomePage"
import { NewsPage } from "../modules/NewsPage"
import { PortfolioPage } from "../modules/PortfolioPage"
import { SustainabilityPage } from "../modules/SustainabilityPage"

const NotFound = () => {
  return (
    <Layout>
      <Seo title="Green Lanes" />
      <Router>
        <HomePage path="/page/" />
        <AboutUsPage path="/page/about-us" />
        <SustainabilityPage path="/page/sustainability" />
        <PortfolioPage path="/page/portfolio" />
        <NewsPage path="/page/news" />
        <ContactPage path="/page/contact" />
        <Article path="/page/news/art/:slug" />
      </Router>
    </Layout>
  )
}

export default NotFound
